<template>
  <div
    :id="`container_${componentId}`"
    :ref="`container_${componentId}`"
    class="avatar_container"
    @mouseenter="loadContacts"
  >
    <div v-if="itemsList.length === 1">
      <component
        :is="to && (isAvatarLink || forceRedirect) ? 'router-link' : 'div'"
        :to="to && (isAvatarLink || forceRedirect) ? getRedirectObject(getItems[0]) : null"
      >
        <b-avatar
          :id="`assigned-contact_item_${getItems[0].id}_${componentId}`"
          :key="`assigned-contact_item_${getItems[0].id}_${componentId}`"
          :to="to && (isAvatarLink || forceRedirect) ? getRedirectObject(getItems[0]) : null"
          class="pull-up"
          style="color: white !important"
          variant="primary"
          size="sm"
          :src="getAvatar(getItems[0])"
          :text="getAvatarText(getItems[0])"
          :target="avatarTarget"
        />

        <b-tooltip
          :key="`assigned-contact_item_tip_${getItems[0].id}_${componentId}`"
          :show="contactActionPopover"
          :target="`assigned-contact_item_${getItems[0].id}_${componentId}`"
          placement="top"
          variant="dark"
          triggers="hover"
        >
          <template v-if="tip">
            {{ $t(tip) }}:
          </template>
          <br>
          {{ `${getFirstName(getItems[0])} ${getLastName(getItems[0])}` }}
        </b-tooltip>
      </component>

      <template v-if="contactActionPopover">
        <b-popover
          :target="`assigned-contact_item_${getItems[0].id}_${componentId}`"
          triggers="hover"
          placement="top"
          :container="`container_${componentId}`"
        >
          <template #title>
            <span class="font-small-2">{{ getUserName(getItems[0]) }}</span>
          </template>

          <div v-if="loading"
               class="text-center"
          >
            <b-spinner variant="primary"
                       size="sm"
            />
          </div>
          <b-list-group v-else
                        flush
          >

            <b-list-group-item v-if="getItems[0].phone">
              <div class="d-flex justify-content-between align-items-center flex-nowrap">
                <div class="mr-1 text-nowrap">
                  <feather-icon class="text-primary mr-25"
                                icon="MessageCircleIcon"
                                size="18"
                  />
                  {{ getItems[0].phone }}
                </div>
                <div>
                  <b-button
                    v-b-tooltip.hover.v-success
                    variant="flat-success"
                    size="sm"
                    class="btn-icon"
                    :title="$t('Copy')"
                    @click.prevent.stop="copy(getItems[0].phone)"
                  >
                    <feather-icon icon="CopyIcon" />
                  </b-button>
                  <b-button v-if="getItems[0].phone && checkRequiredViewPermissions([$viewRanks.SHOW_CONTACT_CARD_SMS]) && checkRequiredPermissions([$roles.CONTACT_THREAD_SMS_VIEW_ALL, $roles.CONTACT_THREAD_SMS_VIEW_SHARED, $roles.CONTACT_THREAD_SMS_VIEW_SELF],[$roles.CONTACT_THREAD_SMS_ADD])"
                            variant="flat-primary"
                            size="sm"
                            @click.prevent.stop="openModal('sms', $t('Sms'), $t('Send'), { sendTo: [getItems[0]] })"
                  >
                    <feather-icon icon="PlusIcon" />
                  </b-button>
                </div>
              </div>
            </b-list-group-item>

            <b-list-group-item v-if="getItems[0].email">
              <div class="d-flex justify-content-between align-items-center flex-nowrap">
                <div class="mr-1 text-nowrap">
                  <feather-icon icon="MailIcon"
                                class="text-primary"
                                size="18"
                  />
                  {{ getItems[0].email | truncate(20) }}
                </div>
                <div>
                  <b-button
                    v-b-tooltip.hover.v-success
                    variant="flat-success"
                    size="sm"
                    class="btn-icon"
                    :title="$t('Copy')"
                    @click.prevent.stop="copy(getItems[0].email)"
                  >
                    <feather-icon icon="CopyIcon" />
                  </b-button>
                  <b-button v-if="getItems[0].email && checkRequiredViewPermissions([$viewRanks.SHOW_CONTACT_CARD_EMAILS]) && checkRequiredPermissions([$roles.CONTACT_THREAD_MAIL_VIEW_ALL], [$roles.CONTACT_THREAD_MAIL_ADD])"
                            variant="flat-primary"
                            size="sm"
                            @click.prevent.stop="openModal('mail', $t('Email'), $t('Send'), { sendTo: { ...getItems[0], type: 'c' } })"
                  >
                    <feather-icon icon="PlusIcon" />
                  </b-button>
                </div>
              </div>
            </b-list-group-item>

          </b-list-group>
        </b-popover>
      </template>
    </div>

    <div
      v-else-if="itemsList.length"
      class="d-flex align-items-center"
    >
      <b-avatar-group :size="String(typeof size !== 'string' ? size + 2 : size)">
        <template v-for="(item, index) in getItems">
          <b-avatar
            :id="`assigned-contact_item_${item.id}_${index}_${componentId}`"
            :key="`assigned-contact_item_${item.id}_${index}_${componentId}`"
            :to="to && (isAvatarLink || forceRedirect) ? getRedirectObject(item) : null"
            class="pull-up"
            style="color: white !important"
            variant="primary"
            :src="getAvatar(item)"
            :text="getAvatarText(item)"
            :target="avatarTarget"
          >
            <!-- Contact Action Popover -->
            <template v-if="contactActionPopover">
              <b-popover
                :container="`container_${componentId}`"
                :target="`assigned-contact_item_${item.id}_${index}_${componentId}`"
                triggers="hover"
                placement="top"
              >
                <template #title>
                  <span class="font-small-2">{{ getUserName(item) }}</span>
                </template>

                <div v-if="loading"
                     class="text-center"
                >
                  <b-spinner variant="primary"
                             size="sm"
                  />
                </div>
                <b-list-group v-else
                              flush
                >

                  <b-list-group-item v-if="item.phone">
                    <div class="d-flex justify-content-between align-items-center">
                      <div class="mr-1">
                        <feather-icon class="text-primary mr-25"
                                      icon="MessageCircleIcon"
                                      size="18"
                        />
                        {{ item.phone }}
                      </div>
                      <div>
                        <b-button
                          v-b-tooltip.hover.v-success
                          variant="flat-success"
                          size="sm"
                          class="btn-icon"
                          :title="$t('Copy')"
                          @click.prevent.stop="copy(item.phone)"
                        >
                          <feather-icon icon="CopyIcon" />
                        </b-button>
                        <b-button v-if="item.phone && checkRequiredViewPermissions([$viewRanks.SHOW_CONTACT_CARD_SMS]) && checkRequiredPermissions([$roles.CONTACT_THREAD_SMS_VIEW_ALL, $roles.CONTACT_THREAD_SMS_VIEW_SHARED, $roles.CONTACT_THREAD_SMS_VIEW_SELF],[$roles.CONTACT_THREAD_SMS_ADD])"
                                  variant="flat-primary"
                                  size="sm"
                                  @click.prevent.stop="openModal('sms', $t('Sms'), $t('Send'), { sendTo: [item] })"
                        >
                          <feather-icon icon="PlusIcon" />
                        </b-button>
                      </div>
                    </div>
                  </b-list-group-item>

                  <b-list-group-item v-if="item.email">
                    <div class="d-flex justify-content-between align-items-center flex-nowrap">
                      <div class="mr-1 text-nowrap">
                        <feather-icon icon="MailIcon"
                                      class="text-primary"
                                      size="18"
                        />
                        {{ item.email | truncate(20) }}
                      </div>
                      <div>
                        <b-button
                          v-b-tooltip.hover.v-success
                          variant="flat-success"
                          size="sm"
                          class="btn-icon"
                          :title="$t('Copy')"
                          @click.prevent.stop="copy(item.email)"
                        >
                          <feather-icon icon="CopyIcon" />
                        </b-button>
                        <b-button v-if="item.email && checkRequiredViewPermissions([$viewRanks.SHOW_CONTACT_CARD_EMAILS]) && checkRequiredPermissions([$roles.CONTACT_THREAD_MAIL_VIEW_ALL], [$roles.CONTACT_THREAD_MAIL_ADD])"
                                  variant="flat-primary"
                                  size="sm"
                                  @click.prevent.stop="openModal('mail', $t('Email'), $t('Send'), { sendTo: { ...item, type: 'c' } })"
                        >
                          <feather-icon icon="PlusIcon" />
                        </b-button>
                      </div>
                    </div>
                  </b-list-group-item>

                </b-list-group>
              </b-popover>
              <span class="font-small-1">{{ getAvatarText(item) }}</span>
            </template>
          <!-- END :: Contact Action Popover -->
          </b-avatar>

          <b-tooltip
            :key="`assigned-contact_item_tip_${item.id}_${index}_${componentId}`"
            :show="contactActionPopover"
            :target="`assigned-contact_item_${item.id}_${index}_${componentId}`"
            placement="top"
            variant="dark"
            triggers="hover"
          >
            <template v-if="tip">
              {{ $t(tip) }}:
            </template>
            <br>
            {{ `${getFirstName(item)} ${getLastName(item)}` }}
          </b-tooltip>
        </template>

        <b-avatar
          v-if="showMore && itemsList.length > max"
          :id="`showMoreAvatarGroup-${componentId}`"
          variant="primary"
        >
          <sw-icon icon="MoreHorizontalIcon" />
        </b-avatar>

        <b-popover
          ref="popover"
          :target="`showMoreAvatarGroup-${componentId}`"
          triggers="hover"
          placement="top"
          container="my-container"
        >
          <b-avatar-group :size="String(typeof size !== 'string' ? size + 2 : size)">
            <template v-for="(item, index) in getItemsOverMax">
              <b-avatar
                :id="`assigned-contact_item_${item.id}_${index}_${componentId}`"
                :key="`assigned-contact_item_${item.id}_${index}_${componentId}`"
                :to="to && (isAvatarLink || forceRedirect) ? getRedirectObject(item) : null"
                class="pull-up"
                style="color: white !important; min-width: 1.34rem;"
                variant="primary"
                :src="getAvatar(item)"
                :text="getAvatarText(item)"
                :target="avatarTarget"
              >
                <!-- Contact Action Popover -->
                <template v-if="contactActionPopover">
                  <b-popover
                    :container="`container_${componentId}`"
                    :target="`assigned-contact_item_${item.id}_${index}_${componentId}`"
                    triggers="hover"
                    placement="top"
                  >
                    <template #title>
                      <span class="font-small-2">{{ getUserName(item) }}</span>
                    </template>

                    <div v-if="loading"
                         class="text-center"
                    >
                      <b-spinner variant="primary"
                                 size="sm"
                      />
                    </div>
                    <b-list-group v-else
                                  flush
                    >

                      <b-list-group-item v-if="item.phone">
                        <div class="d-flex justify-content-between align-items-center">
                          <div class="mr-1">
                            <feather-icon class="text-primary mr-25"
                                          icon="MessageCircleIcon"
                                          size="18"
                            />
                            {{ item.phone }}
                          </div>
                          <div>
                            <b-button
                              v-b-tooltip.hover.v-success
                              variant="flat-success"
                              size="sm"
                              class="btn-icon"
                              :title="$t('Copy')"
                              @click.prevent.stop="copy(item.phone)"
                            >
                              <feather-icon icon="CopyIcon" />
                            </b-button>
                            <b-button v-if="item.phone && checkRequiredViewPermissions([$viewRanks.SHOW_CONTACT_CARD_SMS]) && checkRequiredPermissions([$roles.CONTACT_THREAD_SMS_VIEW_ALL, $roles.CONTACT_THREAD_SMS_VIEW_SHARED, $roles.CONTACT_THREAD_SMS_VIEW_SELF],[$roles.CONTACT_THREAD_SMS_ADD])"
                                      variant="flat-primary"
                                      size="sm"
                                      @click.prevent.stop="openModal('sms', $t('Sms'), $t('Send'), { sendTo: [item] })"
                            >
                              <feather-icon icon="PlusIcon" />
                            </b-button>
                          </div>
                        </div>
                      </b-list-group-item>

                      <b-list-group-item v-if="item.email">
                        <div class="d-flex justify-content-between align-items-center flex-nowrap">
                          <div class="mr-1 text-nowrap">
                            <feather-icon icon="MailIcon"
                                          class="text-primary"
                                          size="18"
                            />
                            {{ item.email | truncate(20) }}
                          </div>
                          <div>
                            <b-button
                              v-b-tooltip.hover.v-success
                              variant="flat-success"
                              size="sm"
                              class="btn-icon"
                              :title="$t('Copy')"
                              @click.prevent.stop="copy(item.email)"
                            >
                              <feather-icon icon="CopyIcon" />
                            </b-button>
                            <b-button v-if="item.email && checkRequiredViewPermissions([$viewRanks.SHOW_CONTACT_CARD_EMAILS]) && checkRequiredPermissions([$roles.CONTACT_THREAD_MAIL_VIEW_ALL], [$roles.CONTACT_THREAD_MAIL_ADD])"
                                      variant="flat-primary"
                                      size="sm"
                                      @click.prevent.stop="openModal('mail', $t('Email'), $t('Send'), { sendTo: { ...item, type: 'c' } })"
                            >
                              <feather-icon icon="PlusIcon" />
                            </b-button>
                          </div>
                        </div>
                      </b-list-group-item>

                    </b-list-group>
                  </b-popover>
                  <span class="font-small-1">{{ getAvatarText(item) }}</span>
                </template>
                <!-- END :: Contact Action Popover -->
              </b-avatar>

              <b-tooltip
                :key="`assigned-contact_item_${item.id}_${index}_${componentId}`"
                :show="contactActionPopover"
                :target="`assigned-contact_item_${item.id}_${index}_${componentId}`"
                placement="top"
                variant="dark"
                triggers="hover"
              >
                <template v-if="tip">
                  {{ $t(tip) }}:
                </template>
                <br>
                {{ `${getFirstName(item)} ${getLastName(item)}` }}
              </b-tooltip>
            </template>
          </b-avatar-group>
        </b-popover>
      </b-avatar-group>
      <div
        v-if="getItemsOverMax.length && !showMore"
        :id="`avatar-group-popover-${componentId}`"
        class="ml-50 text-primary font-small-3"
      >
        +{{ getItemsOverMax.length }} {{ $t('More') }}

        <b-popover
          :target="`avatar-group-popover-${componentId}`"
          triggers="hover"
        >
          <vue-perfect-scrollbar
            :settings="{maxScrollbarLength: 60}"
            style="max-height: 250px"
          >
            <b-list-group flush>
              <b-list-group-item
                v-for="(item, index) in getItemsOverMax"
                :key="`avatar-${item.id}-${componentId}`"
                style="border-bottom: none"
                class="border-left-2 mb-25"
              >
                <b-avatar
                  :id="`avatar-${item.id}_${index}_${componentId}`"
                  :key="`avatar-${item.id}_${index}_${componentId}`"
                  :to="to && (isAvatarLink || forceRedirect) ? getRedirectObject(item) : null"
                  class="pull-up"
                  style="color: white !important"
                  variant="primary"
                  :src="getAvatar(item)"
                  :text="getAvatarText(item)"
                  :target="avatarTarget"
                />

                <b-tooltip
                  :key="`avatar-${item.id}_${index}_${componentId}`"
                  :show="contactActionPopover"
                  :target="`avatar-${item.id}_${index}_${componentId}`"
                  placement="top"
                  variant="dark"
                  triggers="hover"
                >
                  <template v-if="tip">
                    {{ $t(tip) }}:
                  </template>
                  <br>
                  {{ `${getFirstName(item)} ${getLastName(item)}` }}
                </b-tooltip>

                <div class="d-flex justify-content-between align-items-center flex-nowrap">
                  <div>
                    <feather-icon class="text-primary mr-25"
                                  icon="MessageCircleIcon"
                                  size="18"
                    />
                    <span v-if="item.phone">
                      {{ item.phone }}
                    </span>
                    <feather-icon v-else
                                  icon="MinusIcon"
                    />
                  </div>
                  <b-button v-if="item.phone && checkRequiredViewPermissions([$viewRanks.SHOW_CONTACT_CARD_SMS]) && checkRequiredPermissions([$roles.CONTACT_THREAD_SMS_VIEW_ALL, $roles.CONTACT_THREAD_SMS_VIEW_SHARED, $roles.CONTACT_THREAD_SMS_VIEW_SELF],[$roles.CONTACT_THREAD_SMS_ADD])"
                            variant="flat-primary"
                            size="sm"
                            class="btn-icon"
                            @click.prevent.stop="openModal('sms', $t('Sms'), $t('Send'), { sendTo: [item] })"
                  >
                    <feather-icon icon="PlusIcon" />
                  </b-button>
                </div>

                <div class="d-flex justify-content-between align-items-center flex-nowrap">
                  <div>
                    <feather-icon class="text-primary mr-25"
                                  icon="MailIcon"
                                  size="18"
                    />
                    <span v-if="item.email">
                      {{ item.email }}
                    </span>
                    <feather-icon v-else
                                  icon="MinusIcon"
                    />
                  </div>
                  <b-button v-if="item.email && checkRequiredViewPermissions([$viewRanks.SHOW_CONTACT_CARD_EMAILS]) && checkRequiredPermissions([$roles.CONTACT_THREAD_MAIL_VIEW_ALL], [$roles.CONTACT_THREAD_MAIL_ADD])"
                            variant="flat-primary"
                            size="sm"
                            class="btn-icon"
                            @click.prevent.stop="openModal('mail', $t('Email'), $t('Send'), { sendTo: { ...item, type: 'c' } })"
                  >
                    <feather-icon icon="PlusIcon" />
                  </b-button>
                </div>
              </b-list-group-item>
            </b-list-group>
          </vue-perfect-scrollbar>
        </b-popover>
      </div>
    </div>

    <feather-icon
      v-else
      v-b-tooltip.hover.v-primary
      icon="MinusIcon"
      :title="$t(tip)"
    />
  </div>
</template>

<script>
import { BPopover, BTooltip, VBTooltip } from 'bootstrap-vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import useAppCustomizer from '@core/layouts/components/app-customizer/useAppCustomizer'
import axiosIns from '@/libs/axios'
import { OPEN_MODAL } from '@/@constants/mutations'
import { v4 as uuidv4 } from 'uuid'

export default {
  name: 'AvatarGroup',
  components: {
    BTooltip,
    BPopover,
    VuePerfectScrollbar,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    to: {
      type: Object,
      default: null,
    },
    tip: {
      type: String,
      required: false,
      default: 'AssignedUsers',
    },
    contactActionPopover: {
      type: Boolean,
      default: false,
    },
    showTooltip: {
      type: Boolean,
      default: true,
    },
    forceRedirect: {
      type: Boolean,
      default: false,
    },
    showMore: {
      type: Boolean,
      default: false,
    },
    items: {
      type: Array,
      default: () => [],
    },
    itemsField: {
      type: String,
      default: '',
    },
    size: {
      type: [String, Number],
      default: 28,
    },
    max: {
      type: Number,
      default: 4,
    },
  },
  setup() {
    const { isAvatarLink, avatarTarget } = useAppCustomizer()

    return {
      isAvatarLink,
      avatarTarget,
    }
  },
  data: () => ({
    componentId: '',
    itemsList: [],
    isLoaded: false,
    loading: false,
  }),
  computed: {
    getItems() {
      let items = JSON.parse(JSON.stringify(this.itemsList))

      if (this.itemsField) items = items.map(item => item[this.itemsField])

      return items.slice(0, this.max)
    },
    getItemsOverMax() {
      let items = JSON.parse(JSON.stringify(this.itemsList))

      if (this.itemsField) items = items.map(item => item[this.itemsField])

      return items.slice(this.max)
    },
  },
  async mounted() {
    this.itemsList = this.items
  },
  created() {
    this.componentId = uuidv4()
  },
  methods: {
    async loadContacts() {
      if (this.contactActionPopover && !this.isLoaded) {
        this.loading = true
        const contactIds = this.itemsList.mapKeys('id')
        const contactsResponse = await axiosIns.get('1/contacts', {
          params: {
            fields_load: this.$fields.CONTACTS,
            eqArr_id: JSON.stringify(contactIds),
          },
        })

        const loadedContacts = contactsResponse.data?.data?.items || []
        // eslint-disable-next-line vue/no-mutating-props
        this.itemsList = this.itemsList.map(contactItem => {
          const loadedContact = loadedContacts.find(c => c.id === contactItem.id)
          if (loadedContact) {
            return { ...contactItem, ...loadedContact }
          }
          return { ...contactItem }
        })
        this.isLoaded = true

        setTimeout(() => {
          this.loading = false
        }, 500)
      }
    },
    getAvatar(item) {
      return item?.avatar || ''
    },
    getFirstName(item) {
      return item?.firstName || ''
    },
    getLastName(item) {
      return item?.lastName || ''
    },
    getAvatarText(item) {
      return (item?.firstName ? item.firstName[0].toUpperCase() : '') + (item?.lastName ? item.lastName[0].toUpperCase() : '')
    },
    getRedirectObject(item) {
      const link = {
        name: this.to.name,
        params: {
          [`${this.to.name}Id`]: item.id,
        },
      }

      if (item.threadId) link.params.threadId = item.threadId

      return link
    },
    copy(txt) {
      this.$copyText(txt, this.$refs[`container_${this.componentId}`]).then(() => {
        this.showToast('primary', this.$i18n.t('CopiedToClipboard'))
      }, e => {
        this.showToast('danger', e)
      })
    },
    openModal(modalType = 'note', modalTitle = 'Nowa Notatka', okButtonTitle = 'Dodaj notatkę', data = null) {
      this.$store.commit(`modal/${OPEN_MODAL}`,
        {
          modalType,
          modalTitle,
          okButtonTitle,
          size: 'lg',
          data,
        })
    },
  },
}
</script>

<style lang="scss">
.avatar_container {
  .popover {
    min-width: 280px;
    max-width: 350px !important;
    .popover-body {
      padding: 0.65rem 0 !important;
    }
  }
}
</style>
