<template>
  <div>
    <div v-if="!isAssignedContactsEdit">
      <sw-select
        v-if="allowedContacts.length"
        :name="$t('SelectContact')"
      >
        <v-select
          id="select_funnel"
          v-model="selectedContact"
          :options="allowedContacts"
          label="name"
          :reduce="c => c.id"
        >
          <template #no-options>
            {{ $t('NoOptions') }}
          </template>
          <template #selected-option="{ firstName, lastName }">
            {{ firstName }} {{ lastName }}
          </template>
          <template #option="{ firstName, lastName }">
            {{ firstName }} {{ lastName }}
          </template>
        </v-select>
      </sw-select>

      <b-form-group>
        <b-form-checkbox
          v-model="thread.isPrimary"
        >{{ $t('SetAsPrimaryThread') }}</b-form-checkbox>
      </b-form-group>

      <sw-select
        v-if="!modalConfig.editedData"
        :name="$t('SelectFunnel')"
      >
        <v-select
          id="select_funnel"
          v-model="thread.newThreadFunnel"
          :options="funnels"
          label="name"
          :reduce="funnel => funnel.contactThreadFunnelTypePoints[0].id"
          @option:selected="selectFunnel"
        >
          <template #no-options>
            {{ $t('NoOptions') }}
          </template>
          <template #selected-option="{ name }">
            {{ name | truncate(50, '...') }}
          </template>
          <template #option="{ name }">
            {{ name | truncate(50, '...') }}
          </template>
        </v-select>
      </sw-select>

      <b-form-group :label="$t('ThreadName')">
        <b-form-input
          id="popover-input-1"
          ref="input1"
          v-model="thread.newThreadName"
        />
      </b-form-group>
      <!-- checklist - Select  -->
      <sw-select
        v-if="!modalConfig.editedData"
        :name="$t('SelectChecklist')"
      >
        <v-select
          id="select_checklist"
          v-model="thread.newThreadChecklist"
          :options="checklists"
          label="name"
          multiple
          :reduce="checklist => checklist.id.toString()"
        >
          <template #no-options>
            {{ $t('NoOptions') }}
          </template>
          <template #selected-option="{ name }">
            {{ name }}
          </template>
          <template #option="{ name }">
            {{ name }}
          </template>
        </v-select>
      </sw-select>
      <!-- Task for -->
      <div class="mt-1 mb-1">
        <label>{{ $t('AssignedUsers') }}</label>
        <assigned-users
          :value="thread.assignedUsers"
          :is-multiple="true"
          @input="changeAssignedUsers"
        />
      </div>

      <sw-select
        :name="$t('PrimaryUser')"
        :tip="$t('PrimaryUserIsUsedInShortcodes')"
      >
        <v-select
          v-model="thread.assignedLeadUser"
          :options="thread.assignedUsers"
          label="firstName"
          :clearable="false"
        >
          <template #no-options>
            <span>
              {{ $t('NoData') }}
            </span>
          </template>

          <template #option="{ firstName, lastName, avatar }">
            <div class="d-flex align-items-center">
              <avatar
                :user="{ firstName, lastName, avatar }"
                class="mr-25"
                size="sm"
              />
            </div>
          </template>

          <template #selected-option="{ firstName, lastName, avatar }">
            <div class="d-flex">
              <avatar
                :user="{ firstName, lastName, avatar }"
                class="mr-25"
                size="sm"
              />
            </div>
          </template>
        </v-select>
      </sw-select>

      <b-form-group class="mb-1">
        <label>{{ $t('CustomerValue') }}</label>

        <b-row>
          <b-col
            sm="9"
            md="9"
            lg="9"
          >
            <cleave
              v-model="thread.predictedValue"
              :options="cleaveOptions.number"
              class="form-control"
            />
          </b-col>

          <b-col
            sm="3"
            md="3"
            lg="3"
          >
            <sw-select>
              <v-select
                v-model="thread.predictedValueCurrency"
                :options="currencyList"
              >
                <template #no-options>
                  {{ $t('NoOptions') }}
                </template>
              </v-select>
            </sw-select>
          </b-col>
        </b-row>
      </b-form-group>
    </div>

    <!-- Thread Assigned Contacts -->
    <div class="mt-1">
      <label>{{ $t('AssignedContact') }}</label>
      <div
        v-for="(contact, index) in thread.contactThreadAssignedContacts"
        :key="`thread_assigned_user_${index}`"
        class="d-flex flex-wrap flex-md-nowrap mt-50 align-items-center"
      >
        <sw-select class="flex-grow-1 mb-0">
          <v-select
            v-model="contact.contact"
            item-value="id"
            :options="contacts"
            label="firstName"
            class="W-100 mr-50 select-size-sm"
            style="min-width: 255px"
            :filterable="false"
            :selectable="(option) => usedContacts(option)"
            @search="onSearch"
          >
            <template slot="no-options">
              {{ $t('typeToSearchContact') }}
            </template>
            <template #option="{ id, avatar, firstName, lastName }">
              <b-avatar
                size="sm"
                :src="avatar"
                :text="firstName.slice(0,1).toUpperCase() + lastName.slice(0,1).toUpperCase()"
              />
              <span class="ml-50 pt-25"> {{ firstName }} {{ lastName }}  {{ id === '0' ? `- ${$t('Contact')}` : '' }}</span>
            </template>

            <template #selected-option="{ avatar, firstName, lastName }">
              <b-avatar
                size="sm"
                class="border border-white"
                :src="avatar"
                :text="firstName.slice(0,1).toUpperCase() + lastName.slice(0,1).toUpperCase()"
              />
              <span class="ml-50 pt-25"> {{ firstName }} {{ lastName }}</span>
            </template>
          </v-select>
        </sw-select>
        <!-- Tags -->
        <sw-select v-if="false">
          <v-select
            v-model="contact.contactType"
            :options="threadTags"
            :placeholder="$t('ContactTag')"
            label="name"
            class="w-50 select-size-sm"
            :selectable="(option) => usedTags(option)"
            style="min-width: 250px"
          >
            <template #no-options>
              {{ $t('NoOptions') }}
            </template>
            <template #option="{ name, color }">
              <div class="pt-25">
                <div
                  class="color-dot"
                  style="border: 1px solid white"
                  :style="{ backgroundColor: color }"
                />
                {{ name | truncate(30) }}
              </div>
            </template>
            <template #selected-option="{ name, color }">
              <div class="pt-25 position-relative">
                <div
                  class="color-dot"
                  style="border: 1px solid white"
                  :style="{ backgroundColor: color }"
                />
                {{ name | truncate(30) }}
              </div>
            </template>
          </v-select>
        </sw-select>
        <b-input-group
          class="input-group-merge ml-25"
          size="sm"
          style="min-width: 100px"
        >
          <b-form-input
            v-model="contact.part"
            type="number"
            :placeholder="$t('Part') + ' %'"
          />
        </b-input-group>
        <b-button
          class="ml-50"
          variant="flat-danger"
          size="sm"
          @click="deleteContact(index)"
        >
          <feather-icon icon="TrashIcon" />
        </b-button>
      </div>
      <div class="mt-50">
        <b-button
          size="sm"
          variant="flat-success"
          @click="addContact"
        >
          <feather-icon icon="PlusIcon" />
        </b-button>
      </div>
    </div>

    <!--    Footer-->
    <div class="d-flex justify-content-end pt-1 mt-1 border-top">
      <b-button
        variant="secondary"
        size="sm"
        class="mr-50"
        @click="onModalClose"
      >
        {{ $t('Cancel') }}
      </b-button>
      <b-button
        variant="primary"
        size="sm"
        :disabled="thread.newThreadName === '' || !checkAssignedContacts || loading"
        @click="onCreateThread"
      >
        {{ okBtn }}
      </b-button>
    </div>
    <!--    Footer-->
  </div>
</template>

<script>
import vSelect from 'vue-select'
import { mapGetters } from 'vuex'
import AssignedUsers from '@/views/components/AssignedUsers.vue'
import {
  CREATE_NEW_THREAD,
  GET_FUNNELS,
  GET_THREAD_TAGS,
  GET_USER_CART,
  SET_DEFAULT_STATE,
} from '@/@constants/mutations'
import axiosIns from '@/libs/axios'
import { BInputGroup } from 'bootstrap-vue'
import Cleave from 'vue-cleave-component'
import currency from '@/helpers/currency'
import EventBus from '@/event-bus'
import { eEditThread, eNewThread } from '@/@types/events'

export default {
  name: 'TaskModal',
  components: {
    AssignedUsers,
    vSelect,
    BInputGroup,
    Cleave,
  },
  props: {
    okBtn: {
      default: '',
    },
    contactId: {
      default: null,
    },
    allowedContacts: {
      default: () => [],
    },
  },
  data: () => ({
    selectedContact: null,
    contacts: [],
    customerValue: '',
    currencySelected: null,
    currencyList: currency(),
    newContact: {
      contactType: '',
      contact: '',
      part: '',
    },
    thread: {
      assignedUsers: [],
      newThreadName: '',
      assignedLeadUser: '',
      newThreadFunnel: '',
      newThreadChecklist: '',
      contactThreadAssignedContacts: [],
      predictedValue: 0,
      predictedValueCurrency: 'PLN',
    },
    funnels: [],
    checklists: [],
    loading: false,
    cleaveOptions: {
      number: {
        delimiter: ' ',
        numeral: true,
        numeralThousandsGroupStyle: 'thousand',
      },
    },
    isAssignedContactsEdit: false,
  }),
  thread: {
    deep: true,
    handler(newValue) {
      if ((newValue?.assignedUsers || []).length && !this.thread.assignedLeadUser) {
        [this.thread.assignedLeadUser] = newValue
      }
    },
  },
  mounted() {
    if (this.modalConfig.isAssignedContactsEdit) {
      this.isAssignedContactsEdit = this.modalConfig.isAssignedContactsEdit
    }

    // eslint-disable-next-line prefer-destructuring
    this.currencySelected = this.currencyList[0]
    this.$store.dispatch(`typeSettings/${GET_THREAD_TAGS}`)
    this.$store.dispatch(`funnelMain/${GET_FUNNELS}`)
      .then(res => {
        this.funnels = res
      })
      .catch(() => {
        this.showToast('danger', this.$i18n.t('ProblemOccured'))
      })

    axiosIns.get('1/settings/contactThreadChecklistTypes', { params: { fields_load: this.$fields.SETTINGS_FUNNEL_TYPE, orderBy: 'position.asc' } })
      .then(res => {
        this.checklists = res.data.data.items
      })
      .catch(err => {
        this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
      })

    if (this.contactId) {
      this.selectedContact = this.contactId
    }

    if (this.modalConfig.editedData) {
      const editedField = this.modalConfig.editedData
      this.thread = {
        id: editedField.id,
        isPrimary: editedField.isPrimary,
        assignedUsers: JSON.parse(JSON.stringify(editedField.assignedUsers)),
        // eslint-disable-next-line no-nested-ternary
        assignedLeadUser: editedField?.assignedLeadUser ? editedField?.assignedLeadUser : (editedField?.assignedUsers?.length ? editedField.assignedUsers[0] : this.currentUser),
        predictedValue: Number(editedField.predictedValue ?? 0),
        predictedValueCurrency: editedField.predictedValueCurrency ? editedField.predictedValueCurrency : 'PLN',
        newThreadName: editedField.name,
        newThreadFunnel: editedField?.contactThreadFunnelTypePoint?.contactThreadFunnelType,
        newThreadChecklist: editedField.contactThreadChecklistType,
        contactThreadAssignedContacts: JSON.parse(JSON.stringify(editedField.contactThreadAssignedContacts)),
      }
    } else {
      this.thread.assignedUsers = [this.currentUser]
      this.thread.assignedLeadUser = this.currentUser
    }
  },
  computed: {
    ...mapGetters({
      modalConfig: 'modal/getModalState',
      currentUser: 'auth/getCurrentUser',
      threadTags: 'typeSettings/getThreadTags',
    }),
    checkAssignedContacts() {
      if (this.thread.contactThreadAssignedContacts.length) {
        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < this.thread.contactThreadAssignedContacts.length; i++) {
          // if (!this.thread.contactThreadAssignedContacts[i].contactType || !this.thread.contactThreadAssignedContacts[i].contact) return false
          if (!this.thread.contactThreadAssignedContacts[i].contact) return false
        }
      }
      return true
    },
  },
  methods: {
    usedTags(tag) {
      return !this.thread.contactThreadAssignedContacts.map(item => this.getId(item.contactType)).includes(tag.id)
    },
    usedContacts(contact) {
      if (this.$route.params.contactId === contact.id) return false
      return !this.thread.contactThreadAssignedContacts.map(item => this.getId(item.contact)).includes(contact.id)
    },
    addContact() {
      this.thread.contactThreadAssignedContacts.push({ ...this.newContact })
    },
    deleteContact(index) {
      this.thread.contactThreadAssignedContacts.splice(index, 1)
    },
    changeAssignedUsers(payload) {
      if (![...payload].mapKeys('id').includes(this.getObjectId(this.thread.assignedLeadUser))) {
        if (payload.length) [this.thread.assignedLeadUser] = payload
        else this.thread.assignedLeadUser = ''
      }

      this.thread.assignedUsers = payload
    },
    selectFunnel(e) {
      const name = e?.name || this.thread?.newThreadName
      this.thread.newThreadName = name
    },
    onModalClose() {
      this.$emit('close')
    },
    onCreateThread() {
      if (this.newThreadName !== '') {
        const { thread } = this
        const contactId = this.$route.params?.contactId || this.selectedContact || this.modalConfig?.contactId

        const contactThreadChecklistTypes = []

        if (this.modalConfig.editedData) contactThreadChecklistTypes.push(...this.modalConfig.editedData.contactThreadChecklistTypes)

        thread.assignedUsers.forEach((_user, index) => {
          if (typeof (_user) === 'object') thread.assignedUsers[index] = _user.id.toString()
        })

        let pointId = ''

        if (typeof thread.newThreadFunnel === 'object') {
          const point = thread.newThreadFunnel.contactThreadFunnelTypePoints[0]
          pointId = point && typeof point === 'object' ? point.id : point
        } else pointId = thread.newThreadFunnel
        this.loading = true

        const payload = {
          contactId,
          isPrimary: thread.isPrimary,
          assignedLeadUser: this.getObjectId(this.thread.assignedLeadUser),
          predictedValueCurrency: thread.predictedValueCurrency,
          threadName: thread.newThreadName,
          predictedValue: Number(thread.predictedValue ?? 0),
          threadFunnelPoint: pointId,
          assignedUsers: thread.assignedUsers,
          checklistList: Array.isArray(contactThreadChecklistTypes) && contactThreadChecklistTypes.length ? contactThreadChecklistTypes.map(el => el.id) : [],
          contactThreadAssignedContacts: thread.contactThreadAssignedContacts.map(item => ({
            id: item?.id || null,
            contact: this.getId(item.contact),
            // contactType: this.getId(item.contactType),
            contactType: null,
            part: parseFloat(item?.part ?? 0),
          })),
          id: thread.id ? thread.id : null,
        }

        if (Array.isArray(thread.newThreadChecklist) && thread.newThreadChecklist.length) {
          payload.checklistList.push(...thread.newThreadChecklist.map(el => (typeof el === 'string' ? el : el?.id)).filter(Boolean))
        } else if (thread.newThreadChecklist?.id) {
          payload.checklistList.push(thread.newThreadChecklist.id)
        } else if (typeof thread.newThreadChecklist === 'string' && thread.newThreadChecklist) {
          payload.checklistList.push(thread.newThreadChecklist)
        }

        this.$store.dispatch(`singleContact/${CREATE_NEW_THREAD}`, payload)
          .then(id => {
            if (this.checkRequiredModule('offerModule')) {
              this.$store.dispatch(`cart/${GET_USER_CART}`)
            }

            this.$emit('close-modal', { ...payload, id })
            this.showToast('success', this.$i18n.t(`${thread.id ? 'ThreadUpdated' : 'ThreadCreated'}`), this.$i18n.t('alert.Success'))

            EventBus.$emit(thread.id ? eEditThread : eNewThread, { thread: { id } })
          })
          .catch(async err => {
            // eslint-disable-next-line
                        if (err?.response?.data?.message === 'LIMIT_REACHED') {
              await this.showAlert('error', '', this.$i18n.t('PlanLimitReached'), false)
              return
            }
            this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
          })
          .finally(() => {
            this.$store.commit(`modal/${SET_DEFAULT_STATE}`)
            this.$emit('close')
          })
      } else this.showToast('danger', this.$i18n.t('ProblemOccured'))
    },
    checkRequiredModule(module) {
      const settings = this.$store.getters['system/getSettings']
      return !!settings[module]
    },
    onSearch(search, loading) {
      if (search.length) {
        loading(true)
        const payload = {
          search,
        }

        this.$store.dispatch('contacts/GET_SELECT_CONTACTS', payload)
          .then(res => {
            this.contacts = res
            loading(false)
          })
      }
    },
    getId(field) {
      // eslint-disable-next-line no-nested-ternary
      return typeof field === 'object' ? (field?.id ? field.id : '') : field
    },
  },
}
</script>

<style lang="scss">
</style>
