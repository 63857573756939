/* eslint-disable */
import Vue from 'vue'
import store from '@/store'

import { showAlert } from '@core/mixins/ui/alert'
import { showToast } from '@core/mixins/ui/toast'
import checkRanks from '@/helpers/checkRanks'
// eslint-disable-next-line import/no-cycle
import axiosIns from '@/libs/axios'

import { German as de } from 'flatpickr/dist/l10n/de'
import { Polish as pl } from 'flatpickr/dist/l10n/pl'
import { english as en } from 'flatpickr/dist/l10n/default'

import i18n from '@/libs/i18n'
import JSZip from "jszip";

const locales = { de, pl, en }


const getMimeType = (base64Src) => {
  const mime = base64Src.match(/^data:(.*?);base64,/);
  return mime ? mime[1] : 'image/png'; // Domyślnie PNG, jeśli nie znaleziono typu MIME
}
const base64ToBlob = (base64Data, mimeType) => {
  const byteCharacters = atob(base64Data);
  const byteNumbers = new Array(byteCharacters.length);
  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }
  const byteArray = new Uint8Array(byteNumbers);
  return new Blob([byteArray], { type: mimeType });
}

export const checkRequiredPermissions = (...requiredPermissions) => {
  let currentUser = store.getters['auth/getCurrentUser'] || localStorage.getItem('user') || null
  if (!currentUser) return false

  if (typeof currentUser === 'string') currentUser = JSON.parse(currentUser)

  const currentUserPermissions = currentUser.rank?.permissions || []

  return checkRanks(currentUserPermissions, ...requiredPermissions)
}

export const checkRequiredViewPermissions = (...requiredPermissions) => {
  let currentUser = store.getters['auth/getCurrentUser'] || localStorage.getItem('user') || null
  if (!currentUser) return false

  if (typeof currentUser === 'string') currentUser = JSON.parse(currentUser)

  if (currentUser.rank?.permissions?.includes('FULL_ACCESS')) return true
  if (!currentUser.rank?.viewPermissions) return true

  // TODO: change variable name
  const currentUserPermissions = currentUser.rank?.viewPermissions || []

  if (!window.origin.includes('hydropure') && !window.origin.includes('192')) return true

  return checkRanks(currentUserPermissions, ...requiredPermissions)
}

export const checkRequiredModule = (module) => {
  const a = store.getters['system/getSettings'].enabledModules
  const moduleMapping = {
    mailboxModule: { label: 'Moduł poczty e-mail', key: 'MAILBOX' },
    whatsappModule: { label: 'WhatsappModule', key: 'WHATSAPP' },
    offerModule: { label: 'OfferModule', key: 'OFFER' },
    clientMobileModule: { label: 'ClientMobileModule', key: 'CLIENT_MOBILE' },
    pollModule: { label: 'PollModule', key: 'POLL' },
    agreementModule: { label: 'AgreementModule', key: 'AGREEMENT' },
    applicationModule: { label: 'ApplicationModule', key: 'APPLICATION' },
    paymentModule: { label: 'PaymentModule', key: 'PAYMENT' },
    orderModule: { label: 'OrderModule', key: 'ORDER' },
    projectModule: { label: 'ProjectModule', key: 'PROJECT' },
    loyaltyModule: { label: 'LoyaltyModule', key: 'LOYALTY' },
    marketingModule: { label: 'MarketingModule', key: 'MARKETING' },
    campaignModule: { label: 'CampaignModule', key: 'CAMPAIGN' },
    automationModule: { label: 'AutomationModule', key: 'AUTOMATION' },
    newsModule: { label: 'NewsModule', key: 'NEWS' },
    chatGptModule: { label: 'ChatGptModule', key: 'CHAT_GPT' },
    imageRecognitionModule: { label: 'ImageRecognitionModule', key: 'IMAGE_RECOGNITION' },
    complaintModule: { label: 'ComplaintModule', key: 'COMPLAINT' },
    bookingModule: { label: 'BookingModule', key: 'BOOKING' },
    clientBrowserModule: { label: 'clientBrowserModule', key: 'CLIENT_BROWSER' },
    callModule: { label: 'callModule', key: 'CALL' },
    shortcutModule: { label: 'shortcutModule', key: 'SHORTCUT' },
    migrationModule: { label: 'migrationModule', key: 'MIGRATION' },
    calculatorModule: { label: 'migrationModule', key: 'CALCULATOR' },
  }

  if (a) {
    if (Array.isArray(module)) {
      if (!module.length) return true
      return module.some(e => a[moduleMapping[e].key])
    } else {
      const settings = a
      return !!settings[moduleMapping[module].key]
    }
  } else return false
}

export const checkRequiredHostnameFragments = (fragments = [], removeDevs = false) => {
  const url = window.location.hostname
  const allFragments = [...fragments]

  if (!removeDevs) {
    allFragments.push('d1.saleswizardapp.com', '192.168', 'localhost', 'testy')
  }

  let allow = false
  allFragments.forEach(urlFragment => {
    if (url.includes(urlFragment)) {
      allow = true
    }
  })

  return allow
}

/* eslint-disable */
Vue.mixin({
  methods: {
    tableSize(items = [], minHeight = 250, unit = 'px') {
      if (items.length === 0) return 'unset !important'

      return `${minHeight}${unit} !important`
    },
    decodeBase64ToBinary(base64) {
      const raw = window.atob(base64)
      const rawLength = raw.length
      const array = new Uint8Array(new ArrayBuffer(rawLength))

      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < rawLength; i++) {
        array[i] = raw.charCodeAt(i)
      }
      return array
    },
    // Function to download zip file
    downloadZip(files = []) {
      const zip = new JSZip()

      files.forEach(file => {
        const binaryData = this.decodeBase64ToBinary(file.baseContent)
        zip.file(file.filename, binaryData)
      })

      zip.generateAsync({ type: 'blob' }).then(blob => {
        const url = window.URL.createObjectURL(blob)
        const a = document.createElement('a')
        a.href = url
        a.download = 'files.zip'
        a.click()
        window.URL.revokeObjectURL(url)
      })
    },
    secondsToHours(totalSeconds, includeZero = true) {
      const totalMinutes = Math.floor(totalSeconds / 60)

      const seconds = Math.floor(totalSeconds % 60)
      const hours = Math.floor(totalMinutes / 60)
      const minutes = Math.floor(totalMinutes % 60)
      const days = Math.floor(totalSeconds / (3600 * 24))

      return {
        seconds: seconds < 10 && includeZero ? `0${seconds}` : seconds,
        hours: hours < 10 && includeZero ? `0${hours}` : hours,
        minutes: minutes < 10 && includeZero ? `0${minutes}` : minutes,
        days,
      }
    },
    lightenDarkenColor(color, amt = 1) {
      let col = `#${Number(color.split(', ')[0]).toString(16)}${Number(color.split(', ')[1]).toString(16)}${Number(color.split(', ')[2]).toString(16)}`
      var usePound = false;

      if (col[0] == "#") {
        col = col.slice(1);
        usePound = true;
      }

      var num = parseInt(col,16);

      var r = (num >> 16) + amt;

      if (r > 255) r = 255;
      else if  (r < 0) r = 0;

      var b = ((num >> 8) & 0x00FF) + amt;

      if (b > 255) b = 255;
      else if  (b < 0) b = 0;

      var g = (num & 0x0000FF) + amt;

      if (g > 255) g = 255;
      else if (g < 0) g = 0;

      return (usePound?"#":"") + (g | (b << 8) | (r << 16)).toString(16);
    },
    getYoutubeEmbedUrl(url) {
      if (!url) return null
      if (url?.includes('embed')) return url

      const videoId = url?.match(/(?:[?&]v=|\/embed\/|\/v\/|\.be\/|\/embed\?vi?=|\/embed\/v\/)([^#\&\?\/\s]{11})/i);

      if (videoId) return 'https://www.youtube.com/embed/' + videoId[1];

      return null
    },
    async scanContentAndReplaceBaseWithCid(content = '') {
      const root = document.createElement('div');
      root.innerHTML = content;
      const formData = new FormData()

      const images = root.querySelectorAll('img');

      const totalImages = []
      let totalSize = 0
      let tokens = []

      try {
        if (images?.length) {
          images.forEach((img, index) => {
            const base64Src = img.src;

            if (base64Src.startsWith('data:image/')) {
              const uniqueId = `img${Math.random().toString(36).substring(2, 15)}`;
              img.src = `cid:${uniqueId}`;
              const base64Data = base64Src.split(',')[1];
              const blob = base64ToBlob(base64Data, getMimeType(base64Src));

              totalSize += blob.size

              totalImages.push(blob)
              formData.append('files[]', blob, uniqueId + '.' + getMimeType(base64Src).replaceAll('image/'));
            }
          });

          if (totalImages.length) {
            formData.append('imageCompress', 'false')
            const t = await axiosIns.post('storage/1/upload', formData, { noApi: true })
            tokens = t?.data?.data?.files
          }
        }
      } catch (err) {
        console.log(err)
      }

      // Zwracamy zmodyfikowaną treść HTML
      return [root.innerHTML, tokens, totalSize];
    },
    getOrderNo(index, pagination, sorter) {
      let calculatedIndex = index

      if (!sorter?.sortDesc) {
        calculatedIndex = (pagination.totalRecords - 1) - ((pagination.currentPage - 1) * pagination.perPage + index);
      } else {
        calculatedIndex = (pagination.currentPage - 1) * pagination.perPage + index;
      }

      return calculatedIndex + 1
    },
    getHoursByHalf() {
      const hours = [];

      for (let i = 0; i < 24; i++) {
        hours.push(`${i}:00`);
        hours.push(`${i}:30`);
      }

      return hours;
    },
    getKeyByValue(object, value) {
      let result = null;

      function searchNestedObject(obj, val) {
        Object.keys(obj).forEach(key => {
          if (obj[key]?.toLowerCase()?.trim() === val.toLowerCase().trim()) {
            result = key;
          } else if (typeof obj[key] === 'object') {
            searchNestedObject(obj[key], val);
          }
        });
      }

      searchNestedObject(object, value);
      return result;
    },
    getTranslationsField(item, field = 'name') {
      return item.translations?.length ? item.translations[0][field] : ''
    },
    getAddress(item, type = 'default') {
      const types = {
        default: [
          'addressStreet',
          'addressNumber',
          'addressPostalCode',
          'addressCity',
        ],
        correspondence: [
          'addressCorrespondenceStreet',
          'addressCorrespondenceNumber',
          'addressCorrespondencePostalCode',
          'addressCorrespondenceCity',
        ],
      }
      const separators = [' ', ', ', ' ', '']

      const fields = types[type]
      let html = ''

      fields.forEach((field, index) => {
        if (item.isOrganization && item.organization) html += `${item.organization && item.organization[field] ? item.organization[field] : '-'}${separators[index]}`
        else html += `${item[field] || '-'}${separators[index]}`
      })
      return html
    },
    getUserName(user) {
      return `${user?.firstName || ''} ${user?.lastName || ''}`
    },
    showAlert,
    showToast,
    filterByKey(options, search, key = 'name', lang = '') {
      const items = options.filter(option => {
        const value = i18n.te(`${lang}${option[key]}`)
            ? i18n.t(`${lang}${option[key]}`).toLowerCase().includes(search.toLowerCase())
            : option[key].toLowerCase().includes(search.toLowerCase())
        return value
      })
      return items || []
    },
    clog(...text) {
      if (window.location.host.startsWith('localhost:') || window.location.host.startsWith('192.') || window.location.host.startsWith('172.')) {
        console.log(text)
      }
    },
    getCalendarLocale(locale) {
      return locales[locale]
    },
     getObjectId(item) {
      if (typeof item === 'string') return item
      else if (Array.isArray(item)) return item.map(this.getObjectId)
      else if (item?.id) return item.id

      return item || null
    },
    checkRequiredHostnameFragments,
    checkRequiredViewPermissions,
    checkRequiredPermissions,
    checkRequiredModule,
    milionFormatter(number = 0) {
      return (number / 1000000).toFixed(2) + 'M'
    },
    async selectSearch(search, loading, fields_load, search_fields, url, limit = 25, filters, allowEmpty = false) {
      if (!fields_load || !search_fields || !url || !search.length)
        return []

      loading(true)

      try {
        // const _search = search.replaceAll(' ', '%')
        const _search = search.split(' ').map(e => `%${e}%`)

        const params = {
          fields_load,
          search_fields,
          // search: `%${_search}%`,
          searchArr: JSON.stringify(_search),
          limit,
        }

        if (filters) {
          // if (filters?.allowedUsers?.length) params.neqArrAnd_id = JSON.stringify(filters.allowedUsers || [])
          if (filters?.allowedUsers?.length) params.eqArr_id = JSON.stringify(filters.allowedUsers || [])
        }

        const response = await axiosIns.get(url, { params })

        return response?.data?.data?.items
      } catch (err) {
        console.error('Search error', err)
        return err
      } finally {
        loading(false)
      }
    },
  },
})
