var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-observer',{ref:"newScheduleForm",attrs:{"tag":"form"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('validation-provider',{attrs:{"rules":"required","name":_vm.$t('SelectContacts')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('sw-select',{attrs:{"name":_vm.$t('SelectContacts')}},[_c('v-select',{attrs:{"options":_vm.contactThreadList,"label":"name","filterable":false,"clearable":false,"state":errors.length > 0 ? false:null},on:{"search":_vm.loadThreads},scopedSlots:_vm._u([{key:"no-options",fn:function(ref){
var search = ref.search;
return [(search.length)?_c('span',[_vm._v(" "+_vm._s(_vm.$t('NoData'))+" ")]):_c('span',[_vm._v(" "+_vm._s(_vm.$t('TypeToSearch'))+" ")])]}},{key:"option",fn:function(ref){
var name = ref.name;
var contact = ref.contact;
var offersTotal = ref.offersTotal;
return [_c('div',{staticClass:"d-flex align-items-center"},[_c('avatar',{staticClass:"mr-25",attrs:{"user":contact}}),_vm._v(" - "+_vm._s(name)+" "),(offersTotal >= _vm.system.contactThreadOffersLimit)?_c('feather-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip"}],staticClass:"ml-50",attrs:{"icon":"AlertCircleIcon","title":_vm.$tc('ThreadOfferCountIsToBig', _vm.system.contactThreadOffersLimit, { limit: _vm.system.contactThreadOffersLimit })}}):_vm._e()],1)]}},{key:"selected-option",fn:function(ref){
var name = ref.name;
var contact = ref.contact;
return [_c('div',{staticClass:"d-flex"},[_c('avatar',{staticClass:"mr-25",attrs:{"user":contact}}),_vm._v(" – "+_vm._s(name)+" ")],1)]}}],null,true),model:{value:(_vm.newSchedule.payer),callback:function ($$v) {_vm.$set(_vm.newSchedule, "payer", $$v)},expression:"newSchedule.payer"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_c('validation-provider',{attrs:{"rules":"required","name":_vm.$t('ChooseType')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('sw-select',{attrs:{"name":_vm.$t('ChooseType')}},[_c('v-select',{attrs:{"options":_vm.paymentTypeList,"clearable":false,"state":errors.length > 0 ? false:null},scopedSlots:_vm._u([{key:"no-options",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('NoOptions'))+" ")]},proxy:true},{key:"option",fn:function(ref){
var label = ref.label;
return [_c('span',[_vm._v(_vm._s(_vm.$t(_vm.paymentTypes[label])))])]}},{key:"selected-option",fn:function(ref){
var label = ref.label;
return [_c('span',[_vm._v(_vm._s(_vm.$t(_vm.paymentTypes[label])))])]}}],null,true),model:{value:(_vm.newSchedule.type),callback:function ($$v) {_vm.$set(_vm.newSchedule, "type", $$v)},expression:"newSchedule.type"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_c('b-form-group',{attrs:{"label":_vm.$t(_vm.newSchedule.type !== 'LOYALTY_POINTS' ? 'Offer.Price' : 'LoyaltyPoints')}},[_c('b-input-group',[_c('cleave',{staticClass:"form-control",attrs:{"placeholder":_vm.$t(_vm.newSchedule.type !== 'LOYALTY_POINTS' ? 'Offer.Price' : 'LoyaltyPoints'),"options":{
          delimiter: ' ',
          numeral: true,
          numeralThousandsGroupStyle: 'thousand',
        }},model:{value:(_vm.newSchedule.value),callback:function ($$v) {_vm.$set(_vm.newSchedule, "value", $$v)},expression:"newSchedule.value"}}),(_vm.newSchedule.type !== 'LOYALTY_POINTS')?_c('b-input-group-append',[_c('b-button',{attrs:{"variant":"outline-primary","disabled":""}},[_vm._v(" PLN ")])],1):_vm._e()],1)],1),_c('b-form-group',{attrs:{"label":_vm.$t('PaymentDate')}},[_c('validation-provider',{attrs:{"rules":"required","name":_vm.$t('PaymentDate')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var errors = ref.errors;
return [_c('flat-pickr',{staticClass:"form-control",attrs:{"config":{ locale: _vm.getCalendarLocale(_vm.$i18n.locale) },"state":errors.length > 0 ? false:null},model:{value:(_vm.newSchedule.deadlineDate),callback:function ($$v) {_vm.$set(_vm.newSchedule, "deadlineDate", $$v)},expression:"newSchedule.deadlineDate"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":_vm.$t('Note')}},[_c('validation-provider',{attrs:{"name":_vm.$t('Note')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var errors = ref.errors;
return [_c('b-form-textarea',{attrs:{"rows":"4"},model:{value:(_vm.newSchedule.note),callback:function ($$v) {_vm.$set(_vm.newSchedule, "note", $$v)},expression:"newSchedule.note"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-button',{attrs:{"type":"button","variant":"primary","disabled":invalid,"size":"sm"},on:{"click":_vm.saveSchedule}},[_vm._v(" "+_vm._s(_vm.$t('Save'))+" ")])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }