// eslint-disable-next-line import/prefer-default-export
const globalShortcodes = [
  {
    title: 'General',
    shortcodes: [
      { title: 'Logo', code: 'system.logo', sample: 'https://saleswizard.pl/wp-content/uploads/2022/11/logo.png' },
      { title: 'Signet', code: 'system.signet', sample: 'https://saleswizard.orderengine.pl/wp-content/uploads/2022/09/favka.png' },
      { title: 'Favicon', code: 'system.favicon', sample: 'https://saleswizard.orderengine.pl/wp-content/uploads/2022/09/favka.png' },
      { title: 'SystemName', code: 'system.name', sample: 'SalesWizard' },
    ],
  },
  {
    title: 'LoggedUser',
    shortcodes: [
      { title: 'contact.firstName', code: 'loggedUser.firstName', sample: 'Joe' },
      { title: 'contact.lastName', code: 'loggedUser.lastName', sample: 'Doe' },
      { title: 'contact.phone', code: 'loggedUser.phone', sample: '505 606 707' },
      { title: 'contact.email', code: 'loggedUser.email', sample: 'joe@doe.com' },
      { title: 'contact.companyName', code: 'loggedUser.organizationName', sample: 'ExampleIT' },
      { title: 'contact.vatId', code: 'loggedUser.organization.vatId', sample: 'NIP' },
      // New
      {
        title: 'Street', titlePrefix: 'PrimaryAddress', code: 'loggedUser.addressStreet', sample: '17076 Champlin Tunnel Suite 785',
      },
      {
        title: 'BuildingNumber', titlePrefix: 'PrimaryAddress', code: 'loggedUser.addressNumber', sample: '5A',
      },
      {
        title: 'Zip', titlePrefix: 'PrimaryAddress', code: 'loggedUser.addressPostalCode', sample: '06539',
      },
      {
        title: 'City', titlePrefix: 'PrimaryAddress', code: 'loggedUser.addressCity', sample: 'Ressieside',
      },
      {
        title: 'Country', titlePrefix: 'PrimaryAddress', code: 'loggedUser.addressCountry', sample: 'USA',
      },
      {
        title: 'Street', titlePrefix: 'AddressForCorrespondence', code: 'loggedUser.addressCorrespondenceStreet', sample: '17076 Champlin Tunnel Suite 785',
      },
      {
        title: 'BuildingNumber', titlePrefix: 'AddressForCorrespondence', code: 'loggedUser.addressCorrespondenceNumber', sample: '5A',
      },
      {
        title: 'Zip', titlePrefix: 'AddressForCorrespondence', code: 'loggedUser.addressCorrespondencePostalCode', sample: '06539',
      },
      {
        title: 'City', titlePrefix: 'AddressForCorrespondence', code: 'loggedUser.addressCorrespondenceCity', sample: 'Ressieside',
      },
      {
        title: 'Country', titlePrefix: 'AddressForCorrespondence', code: 'loggedUser.addressCorrespondenceCountry', sample: 'USA',
      },
    ],
  },
  {
    title: 'Contact',
    shortcodes: [
      { title: 'contact.firstName', code: 'contact.firstName', sample: 'Joe' },
      { title: 'contact.lastName', code: 'contact.lastName', sample: 'Doe' },
      { title: 'contact.phone', code: 'contact.phone', sample: '505 606 707' },
      { title: 'contact.email', code: 'contact.email', sample: 'joe@doe.com' },
      { title: 'contact.companyName', code: 'contact.organizationName', sample: 'ExampleIT' },
      { title: 'contact.vatId', code: 'contact.organization.vatId', sample: 'NIP' },
      { title: 'ContactPosition', code: 'contact.position', sample: 'Prokurent' },
      {
        title: 'contact.personalId', code: 'contact.personalId', sample: '99030123004',
      },
      {
        title: 'Street', titlePrefix: 'PrimaryAddress', code: 'contact.addressStreet', sample: '17076 Champlin Tunnel Suite 785',
      },
      {
        title: 'BuildingNumber', titlePrefix: 'PrimaryAddress', code: 'contact.addressNumber', sample: '5A',
      },
      {
        title: 'Zip', titlePrefix: 'PrimaryAddress', code: 'contact.addressPostalCode', sample: '06539',
      },
      {
        title: 'City', titlePrefix: 'PrimaryAddress', code: 'contact.addressCity', sample: 'Ressieside',
      },
      {
        title: 'Country', titlePrefix: 'PrimaryAddress', code: 'contact.addressCountry', sample: 'USA',
      },
      {
        title: 'Street', titlePrefix: 'AddressForCorrespondence', code: 'contact.addressCorrespondenceStreet', sample: '17076 Champlin Tunnel Suite 785',
      },
      {
        title: 'BuildingNumber', titlePrefix: 'AddressForCorrespondence', code: 'contact.addressCorrespondenceNumber', sample: '5A',
      },
      {
        title: 'Zip', titlePrefix: 'AddressForCorrespondence', code: 'contact.addressCorrespondencePostalCode', sample: '06539',
      },
      {
        title: 'City', titlePrefix: 'AddressForCorrespondence', code: 'contact.addressCorrespondenceCity', sample: 'Ressieside',
      },
      {
        title: 'Country', titlePrefix: 'AddressForCorrespondence', code: 'contact.addressCorrespondenceCountry', sample: 'USA',
      },
      // Organization
      {
        title: 'Street', titlePrefix: 'OrganizationPrimaryAddress', code: 'contact.organization.addressStreet', sample: '17076 Champlin Tunnel Suite 785',
      },
      {
        title: 'BuildingNumber', titlePrefix: 'OrganizationPrimaryAddress', code: 'contact.organization.addressNumber', sample: '5A',
      },
      {
        title: 'Zip', titlePrefix: 'OrganizationPrimaryAddress', code: 'contact.organization.addressPostalCode', sample: '06539',
      },
      {
        title: 'City', titlePrefix: 'OrganizationPrimaryAddress', code: 'contact.organization.addressCity', sample: 'Ressieside',
      },
      {
        title: 'Country', titlePrefix: 'OrganizationPrimaryAddress', code: 'contact.organization.addressCountry', sample: 'USA',
      },
      {
        title: 'Street', titlePrefix: 'OrganizationAddressForCorrespondence', code: 'contact.organization.addressCorrespondenceStreet', sample: '17076 Champlin Tunnel Suite 785',
      },
      {
        title: 'BuildingNumber', titlePrefix: 'OrganizationAddressForCorrespondence', code: 'contact.organization.addressCorrespondenceNumber', sample: '5A',
      },
      {
        title: 'Zip', titlePrefix: 'OrganizationAddressForCorrespondence', code: 'contact.organization.addressCorrespondencePostalCode', sample: '06539',
      },
      {
        title: 'City', titlePrefix: 'OrganizationAddressForCorrespondence', code: 'contact.organization.addressCorrespondenceCity', sample: 'Ressieside',
      },
      {
        title: 'Country', titlePrefix: 'OrganizationAddressForCorrespondence', code: 'contact.organization.addressCorrespondenceCountry', sample: 'USA',
      },
      // Organization
      {
        title: 'DocumentType', titlePrefix: 'contact.FirstPersonIdentityDocument', code: 'contact.type', sample: 'Dowód osobisty',
      },
      {
        title: 'contact.identityCardNumber', titlePrefix: 'contact.FirstPersonIdentityDocument', code: 'contact.number', sample: 'CBA 11990',
      },
      {
        title: 'contact.authorityIssuing', titlePrefix: 'contact.FirstPersonIdentityDocument', code: 'contact.authorityIssuing', sample: 'Nazwa organu wydającego',
      },
      {
        title: 'contact.releaseDate', titlePrefix: 'contact.FirstPersonIdentityDocument', code: 'contact.releaseDate', sample: '10.01.2022',
      },
      {
        title: 'contact.expirationDate', titlePrefix: 'contact.FirstPersonIdentityDocument', code: 'contact.expirationDate', sample: '10.01.2022',
      },
    ],
  },
  {
    title: 'DestinationPerson',
    shortcodes: [
      { title: 'contact.firstName', code: 'destinationPerson.firstName', sample: 'Joe' },
      { title: 'contact.lastName', code: 'destinationPerson.lastName', sample: 'Doe' },
      { title: 'contact.phone', code: 'destinationPerson.phone', sample: '505 606 707' },
      { title: 'contact.email', code: 'destinationPerson.email', sample: 'joe@doe.com' },
      { title: 'contact.companyName', code: 'destinationPerson.organizationName', sample: 'ExampleIT' },
      { title: 'contact.vatId', code: 'destinationPerson.organization.vatId', sample: 'NIP' },
      {
        title: 'Street', titlePrefix: 'OrganizationPrimaryAddress', code: 'destinationPerson.organization.addressStreet', sample: '17076 Champlin Tunnel Suite 785',
      },
      {
        title: 'BuildingNumber', titlePrefix: 'OrganizationPrimaryAddress', code: 'destinationPerson.organization.addressNumber', sample: '5A',
      },
      {
        title: 'Zip', titlePrefix: 'OrganizationPrimaryAddress', code: 'destinationPerson.organization.addressPostalCode', sample: '06539',
      },
      {
        title: 'City', titlePrefix: 'OrganizationPrimaryAddress', code: 'destinationPerson.organization.addressCity', sample: 'Ressieside',
      },
      {
        title: 'Country', titlePrefix: 'OrganizationPrimaryAddress', code: 'destinationPerson.organization.addressCountry', sample: 'USA',
      },
      {
        title: 'Street', titlePrefix: 'OrganizationAddressForCorrespondence', code: 'destinationPerson.organization.addressCorrespondenceStreet', sample: '17076 Champlin Tunnel Suite 785',
      },
      {
        title: 'BuildingNumber', titlePrefix: 'OrganizationAddressForCorrespondence', code: 'destinationPerson.organization.addressCorrespondenceNumber', sample: '5A',
      },
      {
        title: 'Zip', titlePrefix: 'OrganizationAddressForCorrespondence', code: 'destinationPerson.organization.addressCorrespondencePostalCode', sample: '06539',
      },
      {
        title: 'City', titlePrefix: 'OrganizationAddressForCorrespondence', code: 'destinationPerson.organization.addressCorrespondenceCity', sample: 'Ressieside',
      },
      {
        title: 'Country', titlePrefix: 'OrganizationAddressForCorrespondence', code: 'destinationPerson.organization.addressCorrespondenceCountry', sample: 'USA',
      },
      {
        title: 'Street', titlePrefix: 'PrimaryAddress', code: 'destinationPerson.addressStreet', sample: '17076 Champlin Tunnel Suite 785',
      },
      {
        title: 'BuildingNumber', titlePrefix: 'PrimaryAddress', code: 'destinationPerson.addressNumber', sample: '5A',
      },
      {
        title: 'Zip', titlePrefix: 'PrimaryAddress', code: 'destinationPerson.addressPostalCode', sample: '06539',
      },
      {
        title: 'City', titlePrefix: 'PrimaryAddress', code: 'destinationPerson.addressCity', sample: 'Ressieside',
      },
      {
        title: 'Country', titlePrefix: 'PrimaryAddress', code: 'destinationPerson.addressCountry', sample: 'USA',
      },
      {
        title: 'Street', titlePrefix: 'AddressForCorrespondence', code: 'destinationPerson.addressCorrespondenceStreet', sample: '17076 Champlin Tunnel Suite 785',
      },
      {
        title: 'BuildingNumber', titlePrefix: 'AddressForCorrespondence', code: 'destinationPerson.addressCorrespondenceNumber', sample: '5A',
      },
      {
        title: 'Zip', titlePrefix: 'AddressForCorrespondence', code: 'destinationPerson.addressCorrespondencePostalCode', sample: '06539',
      },
      {
        title: 'City', titlePrefix: 'AddressForCorrespondence', code: 'destinationPerson.addressCorrespondenceCity', sample: 'Ressieside',
      },
      {
        title: 'Country', titlePrefix: 'AddressForCorrespondence', code: 'destinationPerson.addressCorrespondenceCountry', sample: 'USA',
      },
    ],
  },
  {
    title: 'ContactThread',
    shortcodes: [
      { title: 'Link', code: 'contactThread.url', sample: `${window.location.origin}` },
      { title: 'CustomerValue', code: 'contactThread.predictedValue', sample: '1000' },

      { title: 'ThreadName', code: 'contactThread.name', sample: 'Nazwa procesu' },
      {
        title: 'Funnels', code: 'contactThread.contactThreadFunnelProcessSelected.contactThreadFunnelType.name', sample: '1000',
      },
      {
        title: 'FunnelStatus', code: 'contactThread.contactThreadFunnelProcessSelected.contactThreadFunnelTypePoint.name', sample: '1000',
      },
      { title: 'CloseType', code: 'contactThread.contactThreadFunnelProcessSelected.status', sample: '1000' },
      {
        title: 'CloseReasonName', code: 'contactThread.contactThreadFunnelProcessSelected.statusReason', sample: '1000',
      },
      {
        title: 'CloseReasonNote', code: 'contactThread.contactThreadFunnelProcessSelected.statusNote', sample: '1000',
      },
    ],
  },
  {
    title: 'PrimaryUser',
    shortcodes: [
      { title: 'contact.firstName', code: 'leadUser.firstName', sample: 'Joe' },
      { title: 'contact.lastName', code: 'leadUser.lastName', sample: 'Doe' },
      { title: 'contact.phone', code: 'leadUser.phone', sample: '505 606 707' },
      { title: 'contact.email', code: 'leadUser.email', sample: 'joe@doe.com' },
      { title: 'contact.companyName', code: 'leadUser.organizationName', sample: 'ExampleIT' },
      { title: 'contact.vatId', code: 'leadUser.organization.vatId', sample: 'NIP' },
      // New
      // {
      // title: 'Street', titlePrefix: 'OrganizationPrimaryAddress', code: 'leadUser.organization.addressStreet', sample: '17076 Champlin Tunnel Suite 785',
      // },
      // {
      // title: 'BuildingNumber', titlePrefix: 'OrganizationPrimaryAddress', code: 'leadUser.organization.addressNumber', sample: '5A',
      // },
      // {
      // title: 'Zip', titlePrefix: 'OrganizationPrimaryAddress', code: 'leadUser.organization.addressPostalCode', sample: '06539',
      // },
      // {
      // title: 'City', titlePrefix: 'OrganizationPrimaryAddress', code: 'leadUser.organization.addressCity', sample: 'Ressieside',
      // },
      // {
      // title: 'Country', titlePrefix: 'OrganizationPrimaryAddress', code: 'leadUser.organization.addressCountry', sample: 'USA',
      // },
      // {
      // title: 'Street', titlePrefix: 'OrganizationAddressForCorrespondence', code: 'leadUser.organization.addressCorrespondenceStreet', sample: '17076 Champlin Tunnel Suite 785',
      // },
      // {
      // title: 'BuildingNumber', titlePrefix: 'OrganizationAddressForCorrespondence', code: 'leadUser.organization.addressCorrespondenceNumber', sample: '5A',
      // },
      // {
      // title: 'Zip', titlePrefix: 'OrganizationAddressForCorrespondence', code: 'leadUser.organization.addressCorrespondencePostalCode', sample: '06539',
      // },
      // {
      // title: 'City', titlePrefix: 'OrganizationAddressForCorrespondence', code: 'leadUser.organization.addressCorrespondenceCity', sample: 'Ressieside',
      // },
      // {
      // title: 'Country', titlePrefix: 'OrganizationAddressForCorrespondence', code: 'leadUser.organization.addressCorrespondenceCountry', sample: 'USA',
      // },
      //
      {
        title: 'Street', titlePrefix: 'PrimaryAddress', code: 'leadUser.addressStreet', sample: '17076 Champlin Tunnel Suite 785',
      },
      {
        title: 'BuildingNumber', titlePrefix: 'PrimaryAddress', code: 'leadUser.addressNumber', sample: '5A',
      },
      {
        title: 'Zip', titlePrefix: 'PrimaryAddress', code: 'leadUser.addressPostalCode', sample: '06539',
      },
      {
        title: 'City', titlePrefix: 'PrimaryAddress', code: 'leadUser.addressCity', sample: 'Ressieside',
      },
      {
        title: 'Country', titlePrefix: 'PrimaryAddress', code: 'leadUser.addressCountry', sample: 'USA',
      },
      {
        title: 'Street', titlePrefix: 'AddressForCorrespondence', code: 'leadUser.addressCorrespondenceStreet', sample: '17076 Champlin Tunnel Suite 785',
      },
      {
        title: 'BuildingNumber', titlePrefix: 'AddressForCorrespondence', code: 'leadUser.addressCorrespondenceNumber', sample: '5A',
      },
      {
        title: 'Zip', titlePrefix: 'AddressForCorrespondence', code: 'leadUser.addressCorrespondencePostalCode', sample: '06539',
      },
      {
        title: 'City', titlePrefix: 'AddressForCorrespondence', code: 'leadUser.addressCorrespondenceCity', sample: 'Ressieside',
      },
      {
        title: 'Country', titlePrefix: 'AddressForCorrespondence', code: 'leadUser.addressCorrespondenceCountry', sample: 'USA',
      },
    ],
  },
]
export const shortcodes = [
  ...globalShortcodes,
  {
    title: 'offer.Offer',
    shortcodes: [
      { title: 'OfferWebsite', code: 'offer.url', sample: 'https://example.com' },
      { title: 'offer.product.priceNet', code: 'cart.totalPriceWithDiscount', sample: 50000 },
      { title: 'offer.product.priceGross', code: 'cart.totalGrossPriceWithDiscount', sample: 50000 },
    ],
  },
  {
    title: 'Order',
    shortcodes: [
      { title: 'offer.product.priceNet', code: 'cart.totalPriceWithDiscount', sample: 50000 },
      { title: 'offer.product.priceGross', code: 'cart.totalGrossPriceWithDiscount', sample: 50000 },
    ],
  },
  {
    title: 'contactTabs.Payments',
    shortcodes: [
      { title: 'PaymentMethod', code: 'payment.type', sample: 'Przelew' },
      {
        title: 'Costs.AmountToPay', code: 'payment.totalPrice', sample: '20000', options: ['Value', 'Term'],
      },
      {
        title: 'AdvancePayment', code: 'payment.advance', sample: 500, options: ['Value', 'Term'],
      },
    ],
  },
  {
    title: 'contact.agreement',
    shortcodes: [
      { title: 'offer.product.priceGross', code: 'cart.totalGrossPriceWithDiscount', sample: 50000 },
      { title: 'offer.product.priceNet', code: 'cart.totalPriceWithDiscount', sample: 50000 },
    ],
  },
]

export const productShortcodes = [
  {
    title: 'Products',
    shortcodes: [
      { title: 'offer.product.name', code: 'product.name', sample: 'Product name' },
      { title: 'offer.product.code', code: 'product.code', sample: 'XYZ.007' },
      { title: 'offer.product.category', code: 'product.category.1', sample: 'Product category' },
      { title: 'offer.product.subcategory', code: 'product.category.2', sample: 'Product subcategory' },
      { title: 'offer.NettoPrice', code: 'product.totalPriceWithDiscount', sample: 50000.50 },
      { title: 'offer.GrossPrice', code: 'product.totalPriceWithDiscountGross', sample: 50000.50 },
    ],
  },
]

export const signerUserShortcodes = [
  {
    title: 'SignerUser',
    shortcodes: [
      { title: 'contact.firstName', code: 'signerUser.firstName', sample: 'Joe' },
      { title: 'contact.lastName', code: 'signerUser.lastName', sample: 'Doe' },
      { title: 'contact.phone', code: 'signerUser.phone', sample: '505 606 707' },
      { title: 'contact.email', code: 'signerUser.email', sample: 'joe@doe.com' },
      { title: 'contact.companyName', code: 'signerUser.organizationName', sample: 'ExampleIT' },
      {
        title: 'Street', titlePrefix: 'PrimaryAddress', code: 'signerUser.addressStreet', sample: '17076 Champlin Tunnel Suite 785',
      },
      {
        title: 'BuildingNumber', titlePrefix: 'PrimaryAddress', code: 'signerUser.addressNumber', sample: '5A',
      },
      {
        title: 'Zip', titlePrefix: 'PrimaryAddress', code: 'signerUser.addressPostalCode', sample: '06539',
      },
      {
        title: 'City', titlePrefix: 'PrimaryAddress', code: 'signerUser.addressCity', sample: 'Ressieside',
      },
      {
        title: 'Country', titlePrefix: 'PrimaryAddress', code: 'signerUser.addressCountry', sample: 'USA',
      },
      {
        title: 'Street', titlePrefix: 'AddressForCorrespondence', code: 'signerUser.addressCorrespondenceStreet', sample: '17076 Champlin Tunnel Suite 785',
      },
      {
        title: 'BuildingNumber', titlePrefix: 'AddressForCorrespondence', code: 'signerUser.addressCorrespondenceNumber', sample: '5A',
      },
      {
        title: 'Zip', titlePrefix: 'AddressForCorrespondence', code: 'signerUser.addressCorrespondencePostalCode', sample: '06539',
      },
      {
        title: 'City', titlePrefix: 'AddressForCorrespondence', code: 'signerUser.addressCorrespondenceCity', sample: 'Ressieside',
      },
      {
        title: 'Country', titlePrefix: 'AddressForCorrespondence', code: 'signerUser.addressCorrespondenceCountry', sample: 'USA',
      },

      // {
      // title: 'Street', titlePrefix: 'OrganizationPrimaryAddress', code: 'signerUser.organization.addressStreet', sample: '17076 Champlin Tunnel Suite 785',
      // },
      // {
      // title: 'BuildingNumber', titlePrefix: 'OrganizationPrimaryAddress', code: 'signerUser.organization.addressNumber', sample: '5A',
      // },
      // {
      // title: 'Zip', titlePrefix: 'OrganizationPrimaryAddress', code: 'signerUser.organization.addressPostalCode', sample: '06539',
      // },
      // {
      // title: 'City', titlePrefix: 'OrganizationPrimaryAddress', code: 'signerUser.organization.addressCity', sample: 'Ressieside',
      // },
      // {
      // title: 'Country', titlePrefix: 'OrganizationPrimaryAddress', code: 'signerUser.organization.addressCountry', sample: 'USA',
      // },
      // {
      // title: 'Street', titlePrefix: 'OrganizationAddressForCorrespondence', code: 'signerUser.organization.addressCorrespondenceStreet', sample: '17076 Champlin Tunnel Suite 785',
      // },
      // {
      // title: 'BuildingNumber', titlePrefix: 'OrganizationAddressForCorrespondence', code: 'signerUser.organization.addressCorrespondenceNumber', sample: '5A',
      // },
      // {
      // title: 'Zip', titlePrefix: 'OrganizationAddressForCorrespondence', code: 'signerUser.organization.addressCorrespondencePostalCode', sample: '06539',
      // },
      // {
      // title: 'City', titlePrefix: 'OrganizationAddressForCorrespondence', code: 'signerUser.organization.addressCorrespondenceCity', sample: 'Ressieside',
      // },
      // {
      // title: 'Country', titlePrefix: 'OrganizationAddressForCorrespondence', code: 'signerUser.organization.addressCorrespondenceCountry', sample: 'USA',
      // },
      { title: 'Pesel', code: 'signerUser.personalId' },
      { title: 'NIP', code: 'signerUser.organization.vatId' },
      { titlePrefix: 'Document', title: 'task.Type', code: 'signerUser.type' },
      { titlePrefix: 'Document', title: 'contact.authorityIssuing', code: 'signerUser.authorityIssuing' },
      { titlePrefix: 'Document', title: 'OrganizationDetails.addressNumber', code: 'signerUser.number' },
      { titlePrefix: 'Document', title: 'contact.releaseDate', code: 'signerUser.releaseDate' },
      { titlePrefix: 'Document', title: 'contact.expirationDate', code: 'signerUser.expirationDate' },
    ],
  },
]

export const contractorShortcodes = [
  {
    title: 'Contracts.Contractor',
    shortcodes: [
      { title: 'contact.firstName', code: 'contractor.firstName', sample: 'Joe' },
      { title: 'contact.lastName', code: 'contractor.lastName', sample: 'Doe' },
      { title: 'contact.phone', code: 'contractor.phone', sample: '505 606 707' },
      { title: 'contact.email', code: 'contractor.email', sample: 'joe@doe.com' },
      { title: 'ContactPartInAgreement', code: 'contractor.part' },
      { title: 'contact.birthDate', code: 'contractor.birthDate' },
      { title: 'contact.familyName', code: 'contractor.familyName' },
      { title: 'contact.maritalStatus', code: 'contractor.maritalStatus' },
      { title: 'contact.financialPosition', code: 'contractor.financialPosition' },
      { title: 'Pesel', code: 'contractor.personalId' },
      { title: 'contact.companyName', code: 'contractor.organizationName', sample: 'ExampleIT' },
      { title: 'contact.vatId', code: 'contractor.organization.vatId', sample: 'NIP' },
      { titlePrefix: 'Document', title: 'task.Type', code: 'contractor.type' },
      { titlePrefix: 'Document', title: 'contact.authorityIssuing', code: 'contractor.authorityIssuing' },
      { titlePrefix: 'Document', title: 'OrganizationDetails.addressNumber', code: 'contractor.number' },
      { titlePrefix: 'Document', title: 'contact.releaseDate', code: 'contractor.releaseDate' },
      { titlePrefix: 'Document', title: 'contact.expirationDate', code: 'contractor.expirationDate' },
      // {
      // title: 'Street', titlePrefix: 'OrganizationPrimaryAddress', code: 'contractor.organization.addressStreet', sample: '17076 Champlin Tunnel Suite 785',
      // },
      // {
      // title: 'BuildingNumber', titlePrefix: 'OrganizationPrimaryAddress', code: 'contractor.organization.addressNumber', sample: '5A',
      // },
      // {
      // title: 'Zip', titlePrefix: 'OrganizationPrimaryAddress', code: 'contractor.organization.addressPostalCode', sample: '06539',
      // },
      // {
      // title: 'City', titlePrefix: 'OrganizationPrimaryAddress', code: 'contractor.organization.addressCity', sample: 'Ressieside',
      // },
      // {
      // title: 'Country', titlePrefix: 'OrganizationPrimaryAddress', code: 'contractor.organization.addressCountry', sample: 'USA',
      // },
      // {
      // title: 'Street', titlePrefix: 'OrganizationAddressForCorrespondence', code: 'contractor.organization.addressCorrespondenceStreet', sample: '17076 Champlin Tunnel Suite 785',
      // },
      // {
      // title: 'BuildingNumber', titlePrefix: 'OrganizationAddressForCorrespondence', code: 'contractor.organization.addressCorrespondenceNumber', sample: '5A',
      // },
      // {
      // title: 'Zip', titlePrefix: 'OrganizationAddressForCorrespondence', code: 'contractor.organization.addressCorrespondencePostalCode', sample: '06539',
      // },
      // {
      // title: 'City', titlePrefix: 'OrganizationAddressForCorrespondence', code: 'contractor.organization.addressCorrespondenceCity', sample: 'Ressieside',
      // },
      // {
      // title: 'Country', titlePrefix: 'OrganizationAddressForCorrespondence', code: 'contractor.organization.addressCorrespondenceCountry', sample: 'USA',
      // },
      {
        title: 'Street', titlePrefix: 'PrimaryAddress', code: 'contractor.addressStreet', sample: '17076 Champlin Tunnel Suite 785',
      },
      {
        title: 'BuildingNumber', titlePrefix: 'PrimaryAddress', code: 'contractor.addressNumber', sample: '5A',
      },
      {
        title: 'Zip', titlePrefix: 'PrimaryAddress', code: 'contractor.addressPostalCode', sample: '06539',
      },
      {
        title: 'City', titlePrefix: 'PrimaryAddress', code: 'contractor.addressCity', sample: 'Ressieside',
      },
      {
        title: 'Country', titlePrefix: 'PrimaryAddress', code: 'contractor.addressCountry', sample: 'USA',
      },
      {
        title: 'Street', titlePrefix: 'AddressForCorrespondence', code: 'contractor.addressCorrespondenceStreet', sample: '17076 Champlin Tunnel Suite 785',
      },
      {
        title: 'BuildingNumber', titlePrefix: 'AddressForCorrespondence', code: 'contractor.addressCorrespondenceNumber', sample: '5A',
      },
      {
        title: 'Zip', titlePrefix: 'AddressForCorrespondence', code: 'contractor.addressCorrespondencePostalCode', sample: '06539',
      },
      {
        title: 'City', titlePrefix: 'AddressForCorrespondence', code: 'contractor.addressCorrespondenceCity', sample: 'Ressieside',
      },
      {
        title: 'Country', titlePrefix: 'AddressForCorrespondence', code: 'contractor.addressCorrespondenceCountry', sample: 'USA',
      },
    ],
  },
]

export const automationsShortcodes = [
  ...globalShortcodes,
]
