// eslint-disable-next-line import/no-cycle
import axiosIns from '@/libs/axios'
import {
  CREATE_WEBSITE,
  GET_WEBSITE,
  GET_WEBSITES,
  UPDATE_WEBSITE,
} from '@/@constants/mutations'
import * as fields from '@/@constants/fields'

export default {
  namespaced: true,
  actions: {
    // eslint-disable-next-line no-unused-vars
    [GET_WEBSITE]: ({ commit }, websiteId) => new Promise((resolve, reject) => {
      const params = {}

      params.fields_load = fields.SETTINGS_WEBSITES

      axiosIns.get(`1/settings/offerWebTemplate/${websiteId}`, { params })
        .then(res => {
          resolve(res.data)
        })
        .catch(err => {
          reject(err)
        })
    }),

    // eslint-disable-next-line no-unused-vars
    [GET_WEBSITES]: ({ commit }, payload) => new Promise((resolve, reject) => {
      const params = {
        fields_load: fields.SETTINGS_WEBSITES,
      }

      if (payload?.customFields) {
        params.fields_load = payload.customFields
      }

      if (payload?.filters) {
        if (payload?.filters?.active) {
          params.eq_active = 1
        }
      }

      axiosIns.get('1/settings/offerWebTemplate', { params })
        .then(res => {
          resolve(res.data)
        })
        .catch(err => {
          reject(err)
        })
    }),

    // eslint-disable-next-line no-unused-vars
    [CREATE_WEBSITE]: ({ dispatch }, payload) => new Promise((resolve, reject) => {
      axiosIns.post('1/settings/offerWebTemplate', payload)
        .then(res => {
          resolve(res.data)
        })
        .catch(err => {
          reject(err)
        })
    }),

    // eslint-disable-next-line no-unused-vars
    [UPDATE_WEBSITE]: ({ commit }, payload) => new Promise((resolve, reject) => {
      axiosIns.patch('1/settings/offerWebTemplate', payload)
        .then(res => {
          resolve(res.data)
        })
        .catch(err => {
          reject(err)
        })
    }),
  },
}
