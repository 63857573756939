/* eslint-disable */

import {showAlert} from "@core/mixins/ui/alert";
import i18n from "@/libs/i18n";
import router from '@/router'

const __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
  function adopt(value) { return value instanceof P ? value : new P((resolve => { resolve(value) })) }
  return new (P || (P = Promise))(((resolve, reject) => {
    function fulfilled(value) { try { step(generator.next(value)) } catch (e) { reject(e) } }
    function rejected(value) { try { step(generator.throw(value)) } catch (e) { reject(e) } }
    function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected) }
    step((generator = generator.apply(thisArg, _arguments || [])).next())
  }))
}
class ClientWebsocketService {
  constructor(name) {
    this.closed = false
    this.queue = new Map()
    this.queueNextKey = 1
    this.QUEUE_MAX_KEY = Number.MAX_SAFE_INTEGER
    this.subscriptions = new Map()
    this.name = name
    if (ClientWebsocketService.instances.has(name)) {
      throw new Error(`WebSocket '${name}' already initiated.`)
    }
    ClientWebsocketService.instances.set(name, this)
  }

  static getInstance(name) {
    return ClientWebsocketService.instances.get(name)
  }

  connect(params) {
    if (this.ws) {
      return false
    }
    this.closed = false
    const protocols = ['njSocketer']
    if (params.token === undefined) {
      params.token = 'anonymous'
    }
    protocols.push(params.token.replace(/ /g, ''))
    if (params.headers === undefined) {
      params.headers = {}
    }
    protocols.push(Buffer.from(JSON.stringify(params.headers)).toString('hex'))
    try {
      if (params.useInitMessageAsHeaders) {
        this.ws = new WebSocket(params.url)
      } else {
        this.ws = new WebSocket(params.url, protocols)
      }
    } catch (e) {
      // console.error(`Protocols length: ${protocols.join(', ').length}`) // max 7352 (7452 not works)
      // console.error(`Protocols: ${protocols.join(', ')}`) // max 7352 (7452 not works)
      return false
    }
    if (params.useInitMessageAsHeaders) {
      this.setupWebSocket(false, this.ws, params, protocols)
    } else {
      this.setupWebSocket(true, this.ws, params)
    }
    return true
  }

  send(data) {
    this.ws.send(data)
  }

  sendJson(data) {
    this.send(JSON.stringify(data))
  }

  closeAndDelete(code, reason) {
    this.closed = true
    if (this.ws !== undefined) {
      clearInterval(this.pingTimer)
      this.onClose = undefined
      this.onError = undefined
      this.onMessage = undefined
      this.onOpen = undefined
      this.ws.close(code, reason)
      this.ws = undefined
    }
    ClientWebsocketService.instances.delete(this.name)
  }

  fetch(resource_1, data_1) {
    return __awaiter(this, arguments, void 0, function* (resource, data, timeout = 6000000) {
      return this.sendWithResponseData('fetch', {
        name: resource,
      }, data, timeout)
    })
  }

  subscribeConfirm(event_1, callback_1) {
    return __awaiter(this, arguments, void 0, function* (event, callback, timeout = 6000000) {
      // if (this.subscriptions.has(event.name)) {
      //   return false
      // }
      const messageKey = this.getNextMessageKey()
      return new Promise((resolve, reject) => {
        const request = {
          action: 'subscribe',
          messageKey: Buffer.from(messageKey).toString('base64'),
          name: event.name,
          opts: event.opts,
        }
        const timer = setTimeout(() => {
          this.queue.delete(messageKey)
          reject({
            message: `Websocket '${this.name}': Subscribe '${event.name}' timeout`,
            reason: 'Timeout.',
            request,
          })
        }, timeout)

        this.queue.set(messageKey, {
          resolve: () => {
            clearTimeout(timer)
            this.subscriptions.set(event.name, callback)
            resolve()
          },
          reject: reason => {
            clearTimeout(timer)
            reject({
              message: `Websocket '${this.name}': Subscribe '${event.name}' reject (reason: ${reason})`,
              reason,
              request,
            })
          },
        })
        this.sendJson(request)
      })
    })
  }

  unsubscribeConfirm(event_1) {
    return __awaiter(this, arguments, void 0, function* (event, timeout = 6000000) {
      if (!this.subscriptions.has(event.name)) {
        return false
      }
      const messageKey = this.getNextMessageKey()
      return new Promise((resolve, reject) => {
        const request = {
          action: 'unsubscribe',
          messageKey: Buffer.from(messageKey).toString('base64'),
          name: event.name,
          opts: event.opts,
        }
        const timer = setTimeout(() => {
          this.queue.delete(messageKey)
          reject({
            message: `Websocket '${this.name}': Unsubscribe '${event.name}' timeout`,
            reason: 'Timeout.',
            request,
          })
        }, timeout)
        this.queue.set(messageKey, {
          resolve: () => {
            clearTimeout(timer)
            this.subscriptions.delete(event.name)
            resolve(true)
          },
          reject: reason => {
            clearTimeout(timer)
            reject({
              message: `Websocket '${this.name}': Unsubscribe '${event.name}' reject (reason: ${reason})`,
              reason,
              request,
            })
          },
        })
        this.sendJson(request)
      })
    })
  }

  onMessageEvent(event) {
    if (this.queue.size > 0 || this.subscriptions.size > 0) {
      let response
      try {
        response = JSON.parse(event.data)
      } catch (e) {
      }
      if (response !== undefined) {
        // if (response.event === 'fetch') {
        if (response.messageKey !== undefined) {
          // const messageKey = decodeFromBase64<number>(response.messageKey)
          const messageKey = Buffer.from(response.messageKey, 'base64').toString('utf-8')
          const queueData = this.queue.get(messageKey)
          if (queueData) {
            this.queue.delete(response.messageKey)
            if (response.success === true) {
              queueData.resolve(response.data)
            } else {
              queueData.reject(response.message)
            }
          }
        }
        // }
        const callback = this.subscriptions.get(response.event)
        if (callback !== undefined) {
          callback(response.data)
        }
      }
    }
  }

  setupWebSocket(isInitSuccess, ws, params, protocols) {
    // OnMessage
    if (isInitSuccess) {
      ws.onmessage = event => {
        if (this.onMessage) {
          this.onMessage(event)
        }
        this.onMessageEvent(event)
      }
    } else {
      ws.onmessage = event => {
        this.onMessageEvent(event)
      }
    }
    // OnOpen
    if (isInitSuccess) {
      ws.onopen = event => {
        if (this.onOpen) {
          this.onOpen(event)
        }
      }
    } else {
      ws.onopen = event => {
        this.sendWithResponseData('init', {}, protocols)
          .then(() => {
            if (this.ws !== undefined) {
              this.setupWebSocket(true, this.ws, params)
              if (this.onOpen) {
                this.onOpen(event)
              }
            }
          }).catch(() => {
            if (this.ws !== undefined) {
              if (this.onError) {
                this.onError(new Event('init'))
              }
              this.closeAndDelete()
            }
          })
      }
    }
    // OnClose
    if (isInitSuccess) {
      const reconnect = (intervalMultiplier = 1) => {
        if (this.onClose) {
          this.onClose(event)
        }
        this.queue.forEach(value => {
          value.reject('connection closed')
        })
        this.queue.clear()
        this.subscriptions.clear()
        clearInterval(this.pingTimer)
        this.ws = undefined
        if (!this.closed && params.autoReconnect) {
          setTimeout(() => {
            if (!this.closed) {
              this.connect({ ...params, autoReconnect: params.autoReconnect * intervalMultiplier })
            }
          }, params.autoReconnect * intervalMultiplier)
        }
      }
    // eslint-disable-next-line no-param-reassign
      ws.onclose = event => {
        if (router.currentRoute.name === 'client-chat' && event?.code === 429) {
          showAlert('info', '', i18n.t('YouAreConnectedFromDifferentLocation'), true, i18n.t('ConnectAgain'), i18n.t('Cancel'))
            .then(() => {
              reconnect(1)
            })
        } else {
          reconnect(50)
        }
      }
    } else {
      // eslint-disable-next-line no-param-reassign
      ws.onclose = () => {
        this.queue.forEach(value => {
          value.reject('connection closed')
        })
        this.queue.clear()
        this.subscriptions.clear()
        if (this.ws !== undefined) {
          if (this.onError) {
            this.onError(new Event('init'))
          }
          this.closeAndDelete()
        }
      }
    }
    // OnError
    if (isInitSuccess) {
      // eslint-disable-next-line no-param-reassign
      ws.onerror = event => {
        if (this.onError) {
          this.onError(event)
        }
      }
    }
    // Ping
    if (isInitSuccess) {
      clearInterval(this.pingTimer)
      this.pingTimer = setInterval(() => {
        if (this.ws === undefined) {
          clearInterval(this.pingTimer)
        } else {
          this.sendJson({
            action: 'ping',
          })
        }
      }, 15000)
    }
  }

  sendWithResponseData(action_1, addonKeys_1, data_1) {
    return __awaiter(this, arguments, void 0, function* (action, addonKeys, data, timeout = 30000) {
      const messageKey = this.getNextMessageKey()
      return new Promise((resolve, reject) => {
        const request = {
          action, messageKey: Buffer.from(messageKey).toString('base64'), ...addonKeys, data,
        }
        const timer = setTimeout(() => {
          this.queue.delete(messageKey)
          reject({
            message: `Websocket '${this.name}': Action '${action}' timeout:\n${JSON.stringify(data)}`,
            reason: 'Timeout.',
            request,
          })
        }, timeout)
        this.queue.set(messageKey, {
          // eslint-disable-next-line no-shadow
          resolve: data => {
            clearTimeout(timer)
            resolve(data)
          },
          reject: reason => {
            clearTimeout(timer)
            // eslint-disable-next-line prefer-promise-reject-errors
            reject({
              message: `Websocket '${this.name}': Action '${action}' reject (reason: ${reason}):\n${JSON.stringify(data)}`,
              reason,
              request,
            })
          },
        })
        this.sendJson(request)
      })
    })
  }

  getNextMessageKey() {
    let messageKeyNum
    do {
      if (this.queueNextKey >= this.QUEUE_MAX_KEY) {
        this.queueNextKey = 1
      }
      // eslint-disable-next-line no-plusplus
      messageKeyNum = this.queueNextKey++
    } while (this.queue.has(messageKeyNum.toString()))
    return messageKeyNum.toString()
  }
}

ClientWebsocketService.CLOSE_CODE__TO_MANY_REQUEST = 429
ClientWebsocketService.instances = new Map()
export default ClientWebsocketService
